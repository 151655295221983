.addeventatc {
	border: solid 2px !important;
	border-color: dodgerblue !important;
}

.addeventatc_dropdown {
	padding: 6px 0px !important;
}

.addeventatc_dropdown .copyx {
	display: none !important;
}