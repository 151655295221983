a {
    font-weight: bold;
    text-decoration: none;
    color: dodgerblue;
}

a:hover {
    text-decoration: underline;
}

input {
    border: none;
    border-radius: 8px;
    padding: 5px;
    margin-left: 10px;
    background-color: #FAFAFA;
}

button {
    border: none;
    cursor: pointer;
    border-radius: 10px;
    padding: 10px;
    background-color: white;
    font-size: 15px;
    font-weight: bold;
    transition: transform 150ms ease-in-out;
}

.themeBtn {
    cursor: pointer;
    color: white;
    transition: transform 150ms ease-in-out;
}

button:hover, .themeBtn:hover {
    transform: scale(1.05);
}

.header, .settings, .shiftTimes, .breaks, .results, .footer {
    margin: 10px;
    background-color: #2c2c2c;
    color: white;
    border-radius: 15px;
    transition: ease all 0.25s;
}

.header {
    height: 150px;
}

.body {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.flexItem {
    width: 550px;
}

.footer {
    height: 60px;
}

@media screen and (max-width: 700px) {
    .flexItem {
        width: 85%;
    }
}